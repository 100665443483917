import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import BorderedBox from '../components/borderedbox'
import SecondaryNav from '../components/secondarynav'

// Images
import headerimage from '../assets/images/careers/Corprate_HK_Internships_03.jpg'
import mbheaderimage from '../assets/images/careers/Corprate_HK_Internships_MB.jpg'

import icon1 from '../assets/images/careers/Careers_US_Internship_Icon_07.jpg'
import icon2 from '../assets/images/careers/Careers_US_Internship_Icon_05.jpg'
import icon3 from '../assets/images/careers/Careers_US_Internship_Icon_08.jpg'
import icon4 from '../assets/images/careers/Corprate_HK_Internships_Icon_Train.jpg'

import { trackPageData, getPageDetails } from '../analytics'
class HKInternship extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Hong Kong Internships | The Children's Place Corporate Website"
          description="Looking for an internship with The Children's Place in Hong Kong? Learn more about who we're looking for and how to apply on The Children's Place Corporate Website."
        />

        {/* Page Title & Header Image */}
        <div class="row mb-2">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="MAKE OUR PLACE YOURS"
            />
            {/* <p class="text-center">As the largest children's specialty apparel retailer in North America, we take pride in creating clothing kids love and shopping experiences customers appreciate. From our stores, to our corporate office, to our distribution centers and international offices, every employee is a critical part of our success. We’d love for you to come grow with us.</p> */}
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <SecondaryNav
          active="Hong Kong Internships"
          name1="Careers"
          link1="/careers"
          name2="US Internships"
          link2="/us-internships"
          name3="Hong Kong Internships"
          link3="/hong-kong-internships"
        />

        <div class="row mb-5 text-center">
          <div class="col-12">
            <p class="alert alert-primary" role="alert">
              Click{' '}
              <a href="https://childrensplace.wd1.myworkdayjobs.com/en-US/TCP03/job/Corporate-Office---Hong-Kong/Summer-Internship-2020---Hong-Kong_R000014211">
                here
              </a>{' '}
              to apply to the 2020 Hong Kong Internship Program{' '}
            </p>
            <p class="text-large">
              We’re looking for really talented students that are ready to work
              and learn. As an intern at The Children’s Place, graduating
              seniors (or recently graduated students) will explore the retail
              industry, work with inspirational leaders and build on their skill
              set through a 8-week corporate paid internship.
            </p>
            <p class="text-large">
              Interns will get hands-on experience, learn from formal training
              sessions, participate in community service and present a group
              project to the leadership team at the end of the program.
            </p>
            {/* <p>
                        <Link to="#">
                            <button type="button" class="btn btn-outline-primary">APPLY FOR HK INTERNSHIPS</button>
                        </Link>
                    </p> */}
          </div>
        </div>

        <div class="row mb-5 text-center">
          <div class="col-lg-3 col-6">
            <img src={icon1} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>10 weeks</strong>
            </h5>
            <p>
              The amount of time you’ll be working 1-on-1 with us at our PLACE
            </p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={icon2} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>PAID SALARY</strong>
            </h5>
            <p>Because everyone should get paid to work!</p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={icon3} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>9 A.M. - 6 P.M.</strong>
            </h5>
            <p>To work hard, learn tons & make your mark</p>
          </div>
          <div class="col-lg-3 col-6">
            <img src={icon4} className="img-fluid" alt="" />
            <h5
              class="text-uppercase"
              style={{ fontFamily: 'Lato', color: 'black' }}
            >
              <strong>Ngau Tau Kok MTR</strong>
            </h5>
            <p>Convenient way to commute to and from work</p>
          </div>
        </div>

        {/* Key Areas */}
        <div class="row mb-5">
          <div class="col-12">
            <hr class="d-md-none border-top" />
            <BorderedBox classname="mb-n-border">
              <h2>FIND YOUR PLACE IN ONE OF OUR KEY AREAS</h2>
              <ul>
                <div class="row text-left">
                  <div class="col-lg-6 col-md-12">
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Are you detail oriented & love to investigate?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Collaborate with{' '}
                      <strong class="primary">Product Quality</strong>, to
                      examine and test our products to ensure that they meet the
                      highest quality and safety standards.
                    </li>
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Do numbers & finance thrill you?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Get into the game with{' '}
                      <strong class="primary">Finance</strong> and help drive
                      our competitive business results!
                    </li>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Always looking for more efficient ways to do things?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Team up with{' '}
                      <strong class="primary">Global Sourcing</strong> to manage
                      the supply chain and get our goods from factory to
                      customer.
                    </li>
                    <li>
                      <span class="primary">•</span>
                      <strong>
                        Are you great with engaging people?
                        <br class="d-md-none" />
                      </strong>{' '}
                      Join <strong class="primary">Human Resources</strong> to
                      help us recruit, engage and develop our talent!
                    </li>
                  </div>
                </div>
              </ul>
            </BorderedBox>
          </div>
        </div>
        {/* End Key Areas */}
      </Layout>
    )
  }
}

export default HKInternship
